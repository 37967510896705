import React from "react";

const AboutIcon = () => {
  return (
    <svg
      className="about"
      height="1000"
      width="1000"
      strokeMiterlimit="10"
      style={{
        fillRule: "nonzero",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      version="1.1"
      viewBox="0 0 1000 1000"
    >
      <path
        d="M290 493.75C303.333 540.625 336.667 581.25 363.333 600C363.333 606.25 363.333 612.5 363.333 618.75C363.333 625 363.333 631.25 363.333 640.625L376.667 646.875C410 665.625 470 687.5 493.333 687.5C516.667 687.5 570 665.625 603.333 650L626.667 637.5C626.667 631.25 626.667 625 626.667 618.75C626.667 612.5 626.667 606.25 626.667 603.125C656.667 581.25 686.667 540.625 696.667 493.75C716.667 478.125 743.333 446.875 743.333 409.375C743.333 384.375 736.667 362.5 726.667 343.75C740 300 770 178.125 713.333 103.125C696.667 81.25 670 59.375 620 53.125C593.333 21.875 543.333 1.7053e-13 490 1.7053e-13C400 3.125 333.333 28.125 290 78.125C243.333 137.5 233.333 228.125 260 337.5C250 350 240 371.875 240 396.875C246.667 446.875 270 478.125 290 493.75ZM483.333 796.875L456.667 743.75C413.333 731.25 360 706.25 350 700L343.333 696.875C320 712.5 283.333 725 243.333 740.625C140 778.125 10 821.875 0 968.75C0 978.125 3.33333 984.375 10 990.625C13.3333 996.875 20 1000 30 1000L430 1000L430 968.75C430 878.125 483.333 796.875 483.333 796.875ZM753.333 740.625C713.333 728.125 676.667 712.5 653.333 696.875L640 703.125C630 709.375 580 731.25 536.667 743.75L510 796.875C510 796.875 566.667 878.125 566.667 968.75L566.667 1000L966.667 1000C976.667 1000 983.333 996.875 990 990.625C996.667 984.375 1000 975 1000 968.75C986.667 821.875 856.667 775 753.333 740.625Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
    </svg>
  );
};

export default AboutIcon;
