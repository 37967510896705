import React from "react";

const PrevIcon = () => {
  return (
    <svg
      className="prev"
      height="1000"
      strokeMiterlimit="10"
      style={{
        fillRule: "nonzero",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      version="1.1"
      viewBox="0 0 400 1000"
      width="400"
    >
      <path
        d="M363.17 10L36.83 500L363.17 990"
        fill="none"
        opacity="1"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      />
    </svg>
  );
};

export default PrevIcon;
