import React from "react";

const InterestIcon = () => {
  return (
    <svg
      className="interest"
      height="1000"
      strokeMiterlimit="10"
      style={{
        fillRule: "nonzero",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      version="1.1"
      viewBox="0 0 1000 1000"
      width="1000"
    >
      <path
        d="M526.316 131.579L473.684 131.579L473.684 26.3158C473.684 11.7895 485.474 5.68434e-14 500 5.68434e-14L500 5.68434e-14C514.526 5.68434e-14 526.316 11.7895 526.316 26.3158L526.316 131.579ZM197.368 868.421L197.368 868.421C204.605 868.421 210.526 874.342 210.526 881.579L210.526 986.842C210.526 994.079 204.605 1000 197.368 1000L197.368 1000C190.132 1000 184.211 994.079 184.211 986.842L184.211 881.579C184.211 874.342 190.132 868.421 197.368 868.421ZM802.632 868.421L802.632 868.421C809.868 868.421 815.789 874.342 815.789 881.579L815.789 986.842C815.789 994.079 809.868 1000 802.632 1000L802.632 1000C795.395 1000 789.474 994.079 789.474 986.842L789.474 881.579C789.474 874.342 795.395 868.421 802.632 868.421ZM500 657.895L500 657.895C485.526 657.895 473.684 646.053 473.684 631.579L473.684 552.632C473.684 538.158 485.526 526.316 500 526.316L500 526.316C514.474 526.316 526.316 538.158 526.316 552.632L526.316 631.579C526.316 646.053 514.474 657.895 500 657.895Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M131.579 605.263L868.421 605.263L868.421 631.579L131.579 631.579L131.579 605.263Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M881.579 578.947C859.778 578.947 842.105 596.62 842.105 618.421C842.105 640.222 859.778 657.895 881.579 657.895C903.38 657.895 921.053 640.222 921.053 618.421C921.053 596.62 903.38 578.947 881.579 578.947ZM118.421 578.947C96.6203 578.947 78.9474 596.62 78.9474 618.421C78.9474 640.222 96.6203 657.895 118.421 657.895C140.222 657.895 157.895 640.222 157.895 618.421C157.895 596.62 140.222 578.947 118.421 578.947Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M197.368 907.895L197.368 739.816L403.974 306C419.711 327.632 442.605 343.447 468.789 350.579L202.237 907.895L197.368 907.895Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M407.184 329.842C419.526 342.026 434.263 351.737 450.526 358.289L210.526 860.053L210.526 742.789L407.184 329.842M402.921 277.553L184.211 736.842L184.211 921.053L210.526 921.053L488 340.895C449.579 336.474 417.342 311.895 402.921 277.553L402.921 277.553Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M797.763 907.895L531.237 350.579C557.421 343.447 580.316 327.632 596.053 306L802.632 739.816L802.632 907.895L797.763 907.895Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M592.816 329.842L789.474 742.789L789.474 860.053L549.5 358.263C565.737 351.711 580.474 342.026 592.816 329.842M597.079 277.553C582.658 311.895 550.421 336.5 512 340.895L789.474 921.053L815.789 921.053L815.789 736.842L597.079 277.553L597.079 277.553Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M500 131.579C558.053 131.579 605.263 178.789 605.263 236.842C605.263 294.895 558.053 342.105 500 342.105C441.947 342.105 394.737 294.895 394.737 236.842C394.737 178.789 441.947 131.579 500 131.579M500 105.263C427.342 105.263 368.421 164.184 368.421 236.842C368.421 309.5 427.342 368.421 500 368.421C572.658 368.421 631.579 309.5 631.579 236.842C631.579 164.184 572.658 105.263 500 105.263L500 105.263Z"
        fill="#000000"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
    </svg>
  );
};

export default InterestIcon;
