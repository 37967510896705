import React from "react";
import "./../styles/tab.scss";
import "./../styles/tab-content.scss";
import { useDispatch, useSelector } from "react-redux";
import { setShowTab, setActiveTab } from "../store/tabSlice";
import { useSwipeable } from "react-swipeable";
import About from "./tab-contents/About";
import Contact from "./tab-contents/Contact";
import Project from "./tab-contents/Project";
import Skill from "./tab-contents/Skill";
import PrevIcon from "./../icons/PrevIcon";
import NextIcon from "./../icons/NextIcon";
import CaretIcon from "../icons/CaretIcon";

const Tab = () => {
  const showTab = useSelector((state) => state.tab.showTab);
  const activeTab = useSelector((state) => state.tab.activeTab);
  const dispatch = useDispatch();
  const getStyles = () => {
    switch (activeTab) {
      case "__ABOUT":
        return { left: 0 };
      case "__SKILL":
        return { left: "-100vw" };
      case "__PROJECT":
        return { left: "-200vw" };
      case "__CONTACT":
        return { left: "-300vw" };
      default:
        return { left: 0 };
    }
  };
  const goToNextTab = () => {
    switch (activeTab) {
      case "__ABOUT":
        dispatch(setActiveTab("__SKILL"));
        break;
      case "__SKILL":
        dispatch(setActiveTab("__PROJECT"));
        break;
      case "__PROJECT":
        dispatch(setActiveTab("__CONTACT"));
        break;
      default:
        break;
    }
  };
  const goToPrevTab = () => {
    switch (activeTab) {
      case "__CONTACT":
        dispatch(setActiveTab("__PROJECT"));
        break;
      case "__PROJECT":
        dispatch(setActiveTab("__SKILL"));
        break;
      case "__SKILL":
        dispatch(setActiveTab("__ABOUT"));
        break;
      default:
        break;
    }
  };
  const handleSwipe = useSwipeable({
    onSwipedRight: () => goToPrevTab(),
    onSwipedLeft: () => goToNextTab(),
    swipeDuration: 500,
  });
  return (
    <section className="tab" style={showTab ? { top: 0 } : {}} {...handleSwipe}>
      <div className="hide">
        <button onClick={() => dispatch(setShowTab(false))}>
          <span className="line"></span>
          <span className="hide">Hide</span>
          <span className="line"></span>
        </button>
      </div>
      <div
        className="swipe swipe-right"
        style={activeTab === "__ABOUT" ? { display: "none" } : {}}
      >
        <span className="caret">
          <CaretIcon />
        </span>
        <span>Swipe Right</span>
      </div>
      <div
        className="swipe swipe-left"
        style={activeTab === "__CONTACT" ? { display: "none" } : {}}
      >
        <span className="caret">
          <CaretIcon />
        </span>
        <span>Swipe Left</span>
      </div>
      <section className="content-wrapper" style={getStyles()}>
        <About />
        <Skill />
        <Project />
        <Contact />
      </section>
      <button
        className="nav prev"
        style={activeTab === "__ABOUT" ? { display: "none" } : {}}
        onClick={() => goToPrevTab()}
      >
        <PrevIcon />
      </button>
      <button
        className="nav next"
        style={activeTab === "__CONTACT" ? { display: "none" } : {}}
        onClick={() => goToNextTab()}
      >
        <NextIcon />
      </button>
    </section>
  );
};

export default Tab;
