import React from "react";
import "./../styles/landing-page.scss";
import Cup from "./../images/cup.png";
import Menu from "./Menu";
import Tab from "./Tab";
import { useSwipeable } from "react-swipeable";
import { useDispatch } from "react-redux";
import { setShowTab } from "../store/tabSlice";
import CaretIcon from "../icons/CaretIcon";

const LandingPage = () => {
  const dispatch = useDispatch();
  const handleSwipeUp = useSwipeable({
    onSwipedUp: () => dispatch(setShowTab(true)),
    swipeDuration: 500,
  });
  return (
    <section className="landing-page">
      <div className="bg"></div>
      <div className="cup-wrapper" {...handleSwipeUp}>
        <div className="cup">
          <div className="vapour">
            <span style={{ "--v": 1 }}></span>
            <span style={{ "--v": 2 }}></span>
            <span style={{ "--v": 3 }}></span>
            <span style={{ "--v": 9 }}></span>
            <span style={{ "--v": 4 }}></span>
            <span style={{ "--v": 5 }}></span>
            <span style={{ "--v": 10 }}></span>
            <span style={{ "--v": 12 }}></span>
            <span style={{ "--v": 6 }}></span>
            <span style={{ "--v": 7 }}></span>
            <span style={{ "--v": 8 }}></span>
            <span style={{ "--v": 11 }}></span>
          </div>
          <img src={Cup} alt="CUP" />
        </div>
      </div>
      <div className="intro">
        <p>
          Follow your{" "}
          <span className="passion" style={{ "--v": 1 }}>
            PASSIONS
          </span>
          .
        </p>
        <p>
          Let your{" "}
          <span className="dream" style={{ "--v": 2 }}>
            DREAMS
          </span>{" "}
          grow.
        </p>
        <p>
          Do not wait for{" "}
          <span className="next-time" style={{ "--v": 3 }}>
            NEXT TIME
          </span>
          .
        </p>
        <p>
          <span className="opportunity" style={{ "--v": 4 }}>
            OPPORTUNITIES
          </span>{" "}
          slip before you know.
        </p>
      </div>
      <div className="swipe swipe-up">
        <span className="caret">
          <CaretIcon />
        </span>
        <span>Swipe Up</span>
      </div>
      <Menu />
      <Tab />
    </section>
  );
};

export default LandingPage;
