import React from "react";

const PIcon = () => {
  return (
    <svg
      height="1000"
      strokeMiterlimit="10"
      style={{
        fillRule: "nonzero",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      version="1.1"
      fillRule="nonzero"
      viewBox="0 0 1000 1000"
      width="1000"
    >
      <clipPath id="ArtboardFrame">
        <rect height="1000" width="1000" x="0" y="0" />
      </clipPath>
      <g clipPath="url(#ArtboardFrame)" id="Layer-1">
        <path
          d="M500 0C223.858 0 0 223.858 0 500C0 776.142 223.858 1000 500 1000C776.142 1000 1000 776.142 1000 500C1000 223.858 776.142 0 500 0Z"
          fill="#2f4f4f"
          fillRule="nonzero"
          opacity="1"
          stroke="#000000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="2"
        />
        <path
          d="M410.756 54.2491L458.66 454.201L371.885 500L340.5 594.199L463.401 521.702C463.401 521.702 486.904 766.486 462.467 850.509C438.029 934.532 282.255 818.188 282.255 818.188L339.747 925.235C339.747 925.235 482.006 997.949 519.803 898.153C557.6 798.356 523.019 500 523.019 500C523.019 500 679.53 431.492 708.307 329.17C726.482 264.545 733.586 178.126 660.586 113.837C587.586 49.5482 410.756 54.2491 410.756 54.2491ZM628.389 170.901C660.42 214.027 673.188 291.192 614.638 366.542C586.328 402.975 515.313 428.773 515.313 428.773L486.121 125.678C486.121 125.678 588.229 116.83 628.389 170.901Z"
          fill="#f5f5f5"
          fillRule="nonzero"
          opacity="1"
          stroke="#000000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="2"
        />
        <path
          d="M1401.57 1011.69"
          fill="#e84f4b"
          fillRule="nonzero"
          opacity="1"
          stroke="#000000"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default PIcon;
