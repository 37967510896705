import React from "react";
import GmailIcon from "./../../icons/GmailIcon";
import PIcon from "./../../icons/PIcon";
import "./../../styles/tab-contents/contact.scss";

const Contact = () => {
  return (
    <section className="contact content">
      <h1>
        <span>Contact Me</span>
      </h1>
      <div className="detail">
        <div className="words">
          <p>
            You can contact me through either of the following email addresses.
          </p>
        </div>
        <div className="email">
          <div className="gmail">
            <span className="icon">
              <span className="icon-bg"></span>
              <GmailIcon />
            </span>
            <span className="email">
              <span>mail.prashandip@gmail.com</span>
            </span>
          </div>
          <div className="personal">
            <span className="icon p">
              <span className="icon-bg"></span>
              <PIcon />
            </span>
            <span className="email">
              <span>info@prashandip.com.np</span>
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
