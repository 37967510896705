import React, { useState } from "react";
import Portfolio from "./Portfolio";
import ProfilePic from "./../../images/profile-pic.png";
import "./../../styles/tab-contents/portfolio.scss";
import "./../../styles/tab-contents/about.scss";

const About = () => {
  const [showPortfolio, setShowPortfolio] = useState(false);
  return (
    <section className="about content">
      <h1>
        <span>About Me</span>
      </h1>
      <div className="detail">
        <div className="profile">
          <img src={ProfilePic} alt="Profile Pic" />
          <div className="title">
            <h1>Prashandip Limbu</h1>
            <h2>Web Designer</h2>
          </div>
        </div>
        <div className="words">
          <p>I LOVE ART!</p>
          <p>
            The WEB BROWSER, for me, is an infinite sheet of CANVAS that I can
            fill with intriguing DESIGNS.
          </p>
        </div>
      </div>
      <button className="portfolio" onClick={() => setShowPortfolio(true)}>
        <div className="portfolio-button-border"></div>
        <div className="portfolio-button">
          <span>Portfolio Summary</span>
        </div>
      </button>
      <section
        className={`portfolio-dialog ${showPortfolio ? "animate" : ""}`}
        style={showPortfolio ? { transform: "scale(1)" } : {}}
      >
        <section className="wrapper">
          <div className="header">
            <h2>Portfolio Summary</h2>
            <button className="close" onClick={() => setShowPortfolio(false)}>
              CLOSE
            </button>
          </div>
          <Portfolio />
        </section>
      </section>
    </section>
  );
};

export default About;
