import React from "react";
import "./../../styles/tab-contents/skill.scss";
import SKILL_DATA from "./../../data/skill_data";

const Skill = () => {
  return (
    <section className="skill content">
      <h1>
        <span>Skills</span>
      </h1>
      <div className="detail">
        <div className="skill-list">
          <ul>
            {SKILL_DATA.map((skill, i) => {
              return (
                <li key={i}>
                  <div
                    className="li-icon"
                    style={{
                      animationDelay:
                        i === 0 || i === 3
                          ? "0"
                          : i === 1 || i === 4
                          ? "3s"
                          : "6s",
                    }}
                  >
                    {skill.icon}
                    <h2>{skill.title}</h2>
                  </div>
                  <div className="words">
                    <p>{skill.summary}</p>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Skill;
