import React from "react";
import LandingPage from "./components/LandingPage";

import "./styles/main.scss";

function App() {
  return (
    <section>
      <LandingPage />
    </section>
  );
}

export default App;
