import React from "react";

const CssSVG = () => {
  return (
    <svg viewBox="0 0 32 32">
      <defs>
        <linearGradient id="css-gradient" x2="0.35" y2="1">
          <stop offset="0%" stopColor="black" />
          <stop offset="30%" stopColor="black" />
          <stop offset="100%" stopColor="red" />
        </linearGradient>
      </defs>
      <path
        fill="url(#css-gradient)"
        d="M24.235 6.519l-16.47-0.004 0.266 3.277 12.653 0.002-0.319 3.394h-8.298l0.3 3.215h7.725l-0.457 4.403-3.636 1.005-3.694-1.012-0.235-2.637h-3.262l0.362 4.817 6.829 2.128 6.714-1.912 1.521-16.675zM2.879 1.004h26.242l-2.387 26.946-10.763 3.045-10.703-3.047z"
      ></path>
    </svg>
  );
};

export default CssSVG;
