import React from "react";

const NextIcon = () => {
  return (
    <svg
      className="next"
      height="1000"
      strokeMiterlimit="10"
      style={{
        fillRule: "nonzero",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      version="1.1"
      viewBox="0 0 400 1000"
      width="400"
    >
      <path
        d="M36.83 10L363.17 500L36.83 990"
        fill="none"
        opacity="1"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="20"
      />
    </svg>
  );
};

export default NextIcon;
