import React from "react";

const GmailIcon = () => {
  return (
    <svg
      height="1000"
      strokeMiterlimit="10"
      style={{
        fillRule: "nonzero",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      version="1.1"
      viewBox="0 0 1000 1000"
      width="1000"
    >
      <path
        d="M79.5455 875L920.455 875C964.386 875 1000 839.386 1000 795.455L1000 204.545C1000 160.614 964.386 125 920.455 125L79.5455 125C35.6136 125 0 160.614 0 204.545L0 795.455C0 839.386 35.6136 875 79.5455 875Z"
        fill="#e0e0e0"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M545.455 875L920.455 875C964.386 875 1000 839.386 1000 795.455L1000 204.545C1000 160.614 964.386 125 920.455 125L79.5455 125C35.6136 125 0 160.614 0 204.545L545.455 875Z"
        fill="#d9d9d9"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M107.841 875L920.455 875C964.386 875 1000 839.386 1000 795.455L1000 215.909L107.841 875Z"
        fill="#eeeeee"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M539.659 875L920.455 875C964.386 875 1000 839.386 1000 795.455L1000 215.909L381.159 673.091L539.659 875Z"
        fill="#e0e0e0"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M920.455 170.455L79.5455 170.455C35.6136 170.455 0 160.614 0 204.545L0 795.455C0 839.386 35.6136 875 79.5455 875L113.636 875L113.636 227.273L886.364 227.273L886.364 875L920.455 875C964.386 875 1000 839.386 1000 795.455L1000 204.545C1000 160.614 964.386 170.455 920.455 170.455Z"
        fill="#ca3737"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M920.455 125L500 125L79.5455 125C35.6136 125 0 159.909 0 204.545C0 231.955 34.5 255.864 34.5 255.864L500 585.364L965.5 255.886C965.5 255.886 1000 231.955 1000 204.568C1000 159.909 964.386 125 920.455 125Z"
        fill="#f5f5f5"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
      <path
        d="M937.409 126.864L500 431.818L62.5909 126.864C26.8182 134.523 0 165.841 0 204.545C0 231.955 34.5 255.864 34.5 255.864L500 585.364L965.5 255.886C965.5 255.886 1000 231.955 1000 204.568C1000 165.841 973.182 134.523 937.409 126.864Z"
        fill="#e84f4b"
        fillRule="nonzero"
        opacity="1"
        stroke="none"
      />
    </svg>
  );
};

export default GmailIcon;
