import React from "react";

const ContactIcon = () => {
  return (
    <svg
      className="contact"
      height="1000"
      strokeMiterlimit="10"
      style={{
        fillRule: "nonzero",
        clipRule: "evenodd",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
      version="1.1"
      viewBox="0 0 1000 1000"
      width="1000"
    >
      <clipPath id="ArtboardFrame">
        <rect height="1000" width="1000" x="0" y="0" />
      </clipPath>
      <g clipPath="url(#ArtboardFrame)" id="Layer-1">
        <path
          d="M5.68434e-14 127.432C60.25 179.864 405.977 480.818 433.318 504.659C461.432 529.159 489.318 531.818 500 531.818C510.682 531.818 538.568 529.159 566.682 504.659C595.045 479.932 960.909 161.568 1000 127.545C1000 127.545 1000 68.3669 938.492 68.3669C939.493 68.3669 59.8787 68.8315 61.8183 68.1818C0 70.8652 5.68434e-14 127.432 5.68434e-14 127.432ZM5.68434e-14 180.432L5.68434e-14 682.318L302.295 443.614C204.273 358.273 64.6136 236.705 5.68434e-14 180.432Z"
          fill="#000000"
          fillRule="nonzero"
          opacity="1"
          stroke="none"
        />
        <path
          d="M568.182 762C568.355 717.367 613.636 716.545 613.636 716.545L812.682 716.545L769.977 673.841C757.091 660.977 750 643.841 750 625.636C750 607.432 757.091 590.295 769.977 577.432C782.841 564.545 799.977 557.455 818.182 557.455C836.386 557.455 853.523 564.545 866.386 577.432L1000 711.023L1000 192.545C944.977 240.432 666.977 482.386 666.977 482.386C628.227 516.114 600.045 540.636 592.955 546.818C554.614 580.205 515.182 583.818 500 583.818C484.818 583.818 445.386 580.205 407.045 546.795C400.023 540.682 371.841 516.136 333.023 482.341L0 745.318C0 745.318 0 807.456 71.1153 807.456C220.934 807.456 568.182 807.455 568.182 807.455C568.182 807.455 568.337 806.582 568.182 762Z"
          fill="#000000"
          fillRule="nonzero"
          opacity="1"
          stroke="none"
        />
        <g opacity="1" className="no-fill">
          <path
            d="M818.182 613.636L981.06 773.682L818.182 931.818"
            fill="none"
            opacity="1"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="miter"
            strokeWidth="28"
          />
          <path
            d="M630.203 772.727L974.899 773.682"
            fill="none"
            opacity="1"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="miter"
            strokeWidth="28"
          />
        </g>
      </g>
    </svg>
  );
};

export default ContactIcon;
