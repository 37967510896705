import React from "react";
import { useState, useEffect } from "react";
import GmailIcon from "./../../icons/GmailIcon";
import PIcon from "./../../icons/PIcon";
import HtmlIcon from "./../../icons/skills/HtmlIcon";
import CssIcon from "./../../icons/skills/CssIcon";
import ScssIcon from "./../../icons/skills/ScssIcon";
import JsIcon from "./../../icons/skills/JsIcon";
import ReactIcon from "./../../icons/skills/ReactIcon";
import GitIcon from "./../../icons/skills/GitIcon";
import CodeIcon from "./../../icons/details/CodeIcon";
import ClockIcon from "./../../icons/details/ClockIcon";
import CompanyIcon from "./../../icons/details/CompanyIcon";
import UserIcon from "./../../icons/details/UserIcon";
import ProfilePic from "./../../images/profile-pic2.jpg";
import SlidingPuzzleImg from "./../../images/projects/sliding-puzzle.jpg";
import SqSlidingPuzzleImg from "./../../images/projects/square/sq-sliding-puzzle.jpg";
import GMapImg from "./../../images/projects/gmap.jpg";
import SqGMapImg from "./../../images/projects/square/sq-gmap.jpg";
import DigitalProfileImg from "./../../images/projects/digital-profile.jpg";
import SqDigitalProfileImg from "./../../images/projects/square/sq-digital-profile.jpg";
import DrKritiImg from "./../../images/projects/drkriti.jpg";
import SqDrKritiImg from "./../../images/projects/square/sq-drkriti.jpg";
import MoctcaImg from "./../../images/projects/moctca.jpg";
import SqMoctcaImg from "./../../images/projects/square/sq-moctca.jpg";
import DgLinkImg from "./../../images/projects/dglink.jpg";
import SqDgLinkImg from "./../../images/projects/square/sq-dglink.jpg";

const Portfolio = () => {
  const [mediaMedium, setMediaMedium] = useState(
    window.matchMedia("(max-width: 800px)").matches
  );
  const [mediaSmall, setMediaSmall] = useState(
    window.matchMedia("(max-width: 500px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 800px)")
      .addEventListener("change", (e) => setMediaMedium(e.matches));
    window
      .matchMedia("(max-width: 500px)")
      .addEventListener("change", (e) => setMediaSmall(e.matches));
  }, []);
  return (
    <section className="portfolio">
      <div className="profile">
        <img src={ProfilePic} alt="Profile Pic" />
        <div className="title">
          <h1>Prashandip Limbu</h1>
          <h2>Web Designer</h2>
        </div>
      </div>
      <article className="introduction">
        <h3>Introduction</h3>
        <p>
          In my opinion, web design is an art, and web designers are artists.
        </p>
        <p>
          Always striving to be more creative, undertaking subtle design
          choices, thriving to be better with every endeavour; that is how we
          learn.
        </p>
        <p>I prefer to be a simple person, leading a simple life. </p>
        <p>
          And, I want my designs to be minimalist, yet appealing and complete.
        </p>
      </article>
      <article className="contact">
        <h3>Contact Information</h3>
        <p>You can email me at the following email addresses:</p>
        <ul>
          <li>
            <GmailIcon />
            <span>mail.prashandip@gmail.com</span>
          </li>
          <li>
            <PIcon />
            <span>info@prashandip.com.np</span>
          </li>
        </ul>
      </article>
      <article className="skill">
        <h3>Skills</h3>
        <ul>
          <li>
            <span>HTML</span>
            <HtmlIcon />
          </li>
          <li>
            <span>CSS</span>
            <CssIcon />
          </li>
          <li>
            <span>SCSS</span>
            <ScssIcon />
          </li>
          <li>
            <span>JavaScript</span>
            <JsIcon />
          </li>
          <li>
            <span>React</span>
            <ReactIcon />
          </li>
          <li>
            <span>Git</span>
            <GitIcon />
          </li>
        </ul>
      </article>
      <article className="project">
        <h3>Projects</h3>
        <div className="project-grid">
          <div className="project">
            <h4>Sliding Puzzle</h4>
            <div className="detail-wrapper">
              <img
                src={
                  mediaMedium && !mediaSmall
                    ? SqSlidingPuzzleImg
                    : SlidingPuzzleImg
                }
                alt="Sliding Puzzle"
              />
              <div className="project-detail">
                <ul>
                  <li>
                    <p>A simple 3x3 shuffle-and-solve puzzle game.</p>
                  </li>
                  <li>
                    <CodeIcon />
                    <strong>:</strong>
                    <span>HTML, CSS, JavaScript, jQuery, GCP</span>
                  </li>
                  <li>
                    <ClockIcon />
                    <strong>:</strong>
                    <span>Nov 2019 - Dec 2019</span>
                  </li>
                  <li>
                    <CompanyIcon />
                    <strong>:</strong>
                    <span>Personal Project</span>
                  </li>
                  <li>
                    <UserIcon />
                    <strong>:</strong>
                    <span>Myself</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="project">
            <h4>G-Map</h4>
            <div className="detail-wrapper">
              <img
                src={mediaMedium && !mediaSmall ? SqGMapImg : GMapImg}
                alt="G-Map"
              />
              <div className="project-detail">
                <ul>
                  <li>
                    <p>App to search for properties in Australia.</p>
                  </li>
                  <li>
                    <CodeIcon />
                    <strong>:</strong>
                    <span>HTML, CSS, JavaScript, Google Maps API</span>
                  </li>
                  <li>
                    <ClockIcon />
                    <strong>:</strong>
                    <span>Nov 2021 - Dec 2021</span>
                  </li>
                  <li>
                    <CompanyIcon />
                    <strong>:</strong>
                    <span>Upwork Freelancer</span>
                  </li>
                  <li>
                    <UserIcon />
                    <strong>:</strong>
                    <span>Enlightened Solutions, Brisbane, Australia</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="project">
            <h4>Digital Profile</h4>
            <div className="detail-wrapper">
              <img
                src={
                  mediaMedium && !mediaSmall
                    ? SqDigitalProfileImg
                    : DigitalProfileImg
                }
                alt="Digital Profile"
              />
              <div className="project-detail">
                <ul>
                  <li>
                    <p>App with details of tourist attractions in Nepal.</p>
                  </li>
                  <li>
                    <CodeIcon />
                    <strong>:</strong>
                    <span>HTML, CSS, Bootstrap, (ASP.NET) Blazor</span>
                  </li>
                  <li>
                    <ClockIcon />
                    <strong>:</strong>
                    <span>Jan 2021 - Jul 2021</span>
                  </li>
                  <li>
                    <CompanyIcon />
                    <strong>:</strong>
                    <span>Perfect Active Solutions, Kathmandu, Nepal</span>
                  </li>
                  <li>
                    <UserIcon />
                    <strong>:</strong>
                    <span>
                      (Nepal) Ministry of Culture, Tourism and Civil Aviation
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="project">
            <h4>Dr. Kriti</h4>
            <div className="detail-wrapper">
              <img
                src={mediaMedium && !mediaSmall ? SqDrKritiImg : DrKritiImg}
                alt="Dr. Kriti"
              />
              <div className="project-detail">
                <ul>
                  <li>
                    <p>Portfolio website of Dr. Kriti Rai.</p>
                  </li>
                  <li>
                    <CodeIcon />
                    <strong>:</strong>
                    <span>HTML, SCSS, JavaScript, React</span>
                  </li>
                  <li>
                    <ClockIcon />
                    <strong>:</strong>
                    <span>Oct 2022 - Present</span>
                  </li>
                  <li>
                    <CompanyIcon />
                    <strong>:</strong>
                    <span>Team PSR</span>
                  </li>
                  <li>
                    <UserIcon />
                    <strong>:</strong>
                    <span>Dr. Kriti Rai</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="project">
            <h4>MOCTCA</h4>
            <div className="detail-wrapper">
              <img
                src={mediaMedium && !mediaSmall ? SqMoctcaImg : MoctcaImg}
                alt="MOCTCA"
              />
              <div className="project-detail">
                <ul>
                  <li>
                    <p>Website of Ministry of Tourism.</p>
                  </li>
                  <li>
                    <CodeIcon />
                    <strong>:</strong>
                    <span>HTML, CSS, JavaScript, jQuery, Bootstrap</span>
                  </li>
                  <li>
                    <ClockIcon />
                    <strong>:</strong>
                    <span>Mar 2021 - Jul 2021</span>
                  </li>
                  <li>
                    <CompanyIcon />
                    <strong>:</strong>
                    <span>Perfect Active Solutions, Kathmandu, Nepal</span>
                  </li>
                  <li>
                    <UserIcon />
                    <strong>:</strong>
                    <span>
                      (Nepal) Ministry of Culture, Tourism and Civil Aviation
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="project">
            <h4>DG Link</h4>
            <div className="detail-wrapper">
              <img
                src={mediaMedium && !mediaSmall ? SqDgLinkImg : DgLinkImg}
                alt="DG Link"
              />
              <div className="project-detail">
                <ul>
                  <li>
                    <p>Website of DG Link Network Pvt. Ltd.</p>
                  </li>
                  <li>
                    <CodeIcon />
                    <strong>:</strong>
                    <span>HTML, SCSS, JavaScript, React, NextJS</span>
                  </li>
                  <li>
                    <ClockIcon />
                    <strong>:</strong>
                    <span>Sep 2021 - Oct 2021</span>
                  </li>
                  <li>
                    <CompanyIcon />
                    <strong>:</strong>
                    <span>Team PS</span>
                  </li>
                  <li>
                    <UserIcon />
                    <strong>:</strong>
                    <span>DG Link Network Pvt. Ltd</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Portfolio;
