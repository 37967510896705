const PROJECT_DATA = [
  {
    title: "Sliding Puzzle",
    img_name: "sliding-puzzle",
    info: "An intuitive 3x3 shuffle and solve puzzle app. Visit the link and give it a try.",
    tools: "HTML, CSS, JavaScript, jQuery, GCP",
    duration: "Nov 2019 - Dec 2019",
    company: "Personal Project",
    client: "Myself",
    link: {
      exists: true,
      url: "https://sliding-8-puzzle.appspot.com",
    },
  },
  {
    title: "G-Map",
    img_name: "gmap",
    info: "A Google Maps based web application to search for properties in Australia.",
    tools: "HTML, CSS, JavaScript, Google Maps API",
    duration: "Nov 2021 - Dec 2021",
    company: "Upwork Freelancer",
    client: "Enlightened Solutions, Brisbane, Australia",
    link: {
      exists: false,
      url: "",
    },
  },
  {
    title: "Digital Profile",
    img_name: "digital-profile",
    info: "A digital platform to access the information of tourist attractions in Nepal.",
    tools: "HTML, CSS, Bootstrap, (ASP.NET) Blazor",
    duration: "Jan 2021 - Jul 2021",
    company: "Perfect Active Solutions, Kathmandu, Nepal",
    client: "MOCTCA Nepal",
    link: {
      exists: false,
      url: "",
    },
  },
  {
    title: "Dr. Kriti",
    img_name: "drkriti",
    info: "The portfolio website of Dr. Kriti Rai, a skilled dentist and an amazing person.",
    tools: "HTML, SCSS, JavaScript, React",
    duration: "Oct 2022 - Present",
    company: "Team PSR",
    client: "Dr. Kriti Rai",
    link: {
      exists: true,
      url: "https://drkriti.com.np",
    },
  },
  {
    title: "MOCTCA",
    img_name: "moctca",
    info: "The official website of Ministry of Culture, Tourism and Civil Aviation (MOCTCA) Nepal.",
    tools: "HTML, CSS, JavaScript, jQuery, Bootstrap",
    duration: "Mar 2021 - Jul 2021",
    company: "Perfect Active Solutions, Kathmandu, Nepal",
    client: "MOCTCA Nepal",
    link: {
      exists: true,
      url: "https://www.tourism.gov.np",
    },
  },
  {
    title: "DG Link",
    img_name: "dglink",
    info: "The official website of DG Link Network Pvt. Ltd., a reputable internet service provider.",
    tools: "HTML, SCSS, JavaScript, React, NextJS",
    duration: "Sep 2021 - Oct 2021",
    company: "Team PS",
    client: "DG Link Network Pvt. Ltd",
    link: {
      exists: true,
      url: "https://dglink.com.np",
    },
  },
];
export default PROJECT_DATA;
