import React from "react";
import "./../styles/menu.scss";
import AboutIcon from "../icons/AboutIcon";
import ContactIcon from "../icons/ContactIcon";
import SkillIcon from "../icons/SkillIcon";
import ProjectIcon from "../icons/ProjectIcon";
import { useDispatch } from "react-redux";
import { setActiveTab, setShowTab } from "../store/tabSlice";

const Menu = () => {
  const dispatch = useDispatch();
  const handleMenuClick = (tabName) => {
    dispatch(setActiveTab(tabName));
    dispatch(setShowTab(true));
  };
  return (
    <div className="menu">
      <button type="button" onClick={() => handleMenuClick("__ABOUT")}>
        <div className="icon about">
          <AboutIcon />
        </div>
        <span>About</span>
      </button>
      <button type="button" onClick={() => handleMenuClick("__SKILL")}>
        <div className="icon interest">
          <SkillIcon />
        </div>
        <span>Skills</span>
      </button>
      <button type="button" onClick={() => handleMenuClick("__PROJECT")}>
        <div className="icon project">
          <ProjectIcon />
        </div>
        <span>Projects</span>
      </button>
      <button type="button" onClick={() => handleMenuClick("__CONTACT")}>
        <div className="icon contact">
          <ContactIcon />
        </div>
        <span>Contact</span>
      </button>
    </div>
  );
};

export default Menu;
