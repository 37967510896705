import React, { useState, useEffect } from "react";
import "./../../styles/tab-contents/project.scss";
import PROJECT_DATA from "../../data/project-data";

const Project = () => {
  const [active, setActive] = useState(1);
  const [mediaMedium, setMediaMedium] = useState(
    window.matchMedia("(max-width: 1000px)").matches
  );
  const [mediaSmall, setMediaSmall] = useState(
    window.matchMedia("(max-width: 620px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 1000px)")
      .addEventListener("change", (e) => setMediaMedium(e.matches));
    window
      .matchMedia("(max-width: 620px)")
      .addEventListener("change", (e) => setMediaSmall(e.matches));
  }, []);
  const activeGradient =
    "linear-gradient(to top right, rgb(200, 200, 220), white)";
  const passiveGradient =
    "linear-gradient(155deg, rgb(240, 240, 255), rgba(240, 240, 255, 0.9) 50%, transparent)";
  return (
    <section className="project content">
      <h1>
        <span>Projects</span>
      </h1>
      <div className="detail">
        <div className="project-grid">
          {PROJECT_DATA.map((project, i) => {
            const n = i + 1;
            return (
              n <= 3 && (
                <div
                  key={n}
                  onClick={() => setActive(n)}
                  className={`project ${active === n ? "active" : ""}`}
                  style={
                    n === 1
                      ? {
                          backgroundImage:
                            active === 1
                              ? activeGradient
                              : passiveGradient +
                                `, url(${require(`./../../images/projects/${project.img_name}.jpg`)})`,
                          ...(active === 1
                            ? { top: 0 }
                            : active === 2 && !mediaMedium
                            ? { top: 0 }
                            : active === 2 && mediaMedium
                            ? { top: "70%" }
                            : active === 3 && mediaMedium
                            ? { top: 0, left: 0 }
                            : { top: "52%" }),
                          ...(mediaSmall ? { top: "auto", left: "auto" } : {}),
                        }
                      : n === 2
                      ? {
                          backgroundImage:
                            active === 2
                              ? activeGradient
                              : passiveGradient +
                                `, url(${require(`./../../images/projects/${project.img_name}.jpg`)})`,
                          ...(active === 1 && !mediaMedium
                            ? { left: "62%" }
                            : active === 1 && mediaMedium
                            ? { left: 0, top: "70%" }
                            : active === 2 && !mediaMedium
                            ? { left: "40%" }
                            : active === 2 && mediaMedium
                            ? { left: 0 }
                            : active === 3 && mediaMedium
                            ? { top: 0, left: "51%" }
                            : { left: 0 }),
                          ...(mediaSmall ? { top: "auto", left: "auto" } : {}),
                        }
                      : {
                          backgroundImage:
                            active === 3
                              ? activeGradient
                              : passiveGradient +
                                `, url(${require(`./../../images/projects/${project.img_name}.jpg`)})`,
                          ...(active === 1 && !mediaMedium
                            ? { top: "52%", left: "62%" }
                            : active === 1 && mediaMedium
                            ? { top: "70%", left: "51%" }
                            : active === 2 && !mediaMedium
                            ? { top: "52%", left: 0 }
                            : active === 2 && mediaMedium
                            ? { top: "70%", left: "51%" }
                            : active === 3 && mediaMedium
                            ? { top: "32.5%", left: 0 }
                            : { top: 0, left: "40%" }),
                          ...(mediaSmall ? { top: "auto", left: "auto" } : {}),
                        }
                  }
                >
                  <h2>{project.title}</h2>
                  <div className="detail-wrapper">
                    {active === n && (
                      <img
                        src={
                          mediaMedium && !mediaSmall
                            ? require(`./../../images/projects/square/sq-${project.img_name}.jpg`)
                            : require(`./../../images/projects/${project.img_name}.jpg`)
                        }
                        alt={project.title}
                      />
                    )}
                    {active === n ? (
                      <div className="project-detail">
                        <ul>
                          <li>
                            <p>{project.info}</p>
                          </li>
                          <li>
                            <h3>Tools</h3>
                            <strong>:</strong>
                            <span>{project.tools}</span>
                          </li>
                          <li>
                            <h3>Duration</h3>
                            <strong>:</strong>
                            <span>{project.duration}</span>
                          </li>
                          <li>
                            <h3>Company</h3>
                            <strong>:</strong>
                            <span>{project.company}</span>
                          </li>
                          <li>
                            <h3>Client</h3>
                            <strong>:</strong>
                            <span>{project.client}</span>
                          </li>
                          {project.link.exists && (
                            <li>
                              <a
                                href={project.link.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Open App (in New Tab)
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                    ) : (
                      <div className="expand">
                        <p>Click to expand.</p>
                      </div>
                    )}
                  </div>
                </div>
              )
            );
          })}
        </div>
        <p>
          <i>See PORTFOLIO SUMMARY in ABOUT ME for more.</i>
        </p>
      </div>
    </section>
  );
};

export default Project;
