import { createSlice } from "@reduxjs/toolkit";

export const tabSlice = createSlice({
  name: "tab",
  initialState: {
    showTab: false,
    activeTab: "__ABOUT",
  },
  reducers: {
    setShowTab: (state, action) => {
      state.showTab = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
  },
});

export const { setShowTab, setActiveTab } = tabSlice.actions;

export default tabSlice.reducer;
